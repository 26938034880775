import logo from './logo.svg';
import './App.css';
import React, { Suspense } from 'react';

const RemoteComponent = React.lazy(() => import('microui1/MicroComponent'));

function App() {
  return (
    <div className="App">
      <div>
        Link source code App
        <br/>
        https://gitlab.com/microui/myapp.git
      </div>
        <Suspense fallback={<div>Loading...</div>}>
          <RemoteComponent />
        </Suspense>
    </div>
  );
}

export default App;
